import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
const styles = (theme) => ({
    main:{
        
    }
});


class Main extends React.Component {
    constructor() {
        super();
        this.state = {

        };

    }
 

    render() {
        const { classes }=this.props;

        return (
            <div>InstaMonet</div>
        );
    }
}

export default withStyles(styles)(Main);